import type { ILocalNotification } from 'cordova-plugin-local-notification';
import { get } from 'lodash';

import { Platform } from '@maggie/cordova/platform';

import { config } from './config';

let registrationId: string | undefined;
let listenersSet = false;

const getLocalPlugin = (): LocalNotification | undefined =>
  get(window, 'cordova.plugins.notification.local');

const schedule = (notif: ILocalNotification | ILocalNotification[]) => {
  if (!Array.isArray(notif)) {
    notif = [notif];
  }
  if (Platform.get() === 'Android') {
    notif = notif.map(n => ({ ...n, ...config.android }));
  }
  const plugin = getLocalPlugin();
  if (plugin) {
    plugin.schedule(notif);
  }
};

const cancel = async (ids: number | number[]) => {
  const plugin = getLocalPlugin();
  if (!plugin) {
    return;
  }
  return new Promise<void>((resolve, reject) => {
    try {
      plugin.cancel(ids, resolve);
    } catch (err) {
      reject(err);
    }
  });
};

const cancelAll = () => {
  const plugin = getLocalPlugin();
  if (!plugin) {
    return;
  }

  plugin.cancelAll?.();
};

const initLocalClickListener = () => {
  const plugin = getLocalPlugin();
  if (!listenersSet && plugin && plugin.on) {
    //To set dummyNotifications to get notification for Android 13
    plugin.setDummyNotifications();

    listenersSet = true;
    plugin.on('click', (notification?: any) => {
      if (typeof (notification ? notification.data : undefined) === 'object') {
        if (get(notification, 'data.type') === 'prizing') {
          window.__router.navigate('game', {});
        } else if (get(notification, 'data.type') === 'content' && notification.data.lessonId) {
          window.__router.navigate('lesson', { id: notification.data.lessonId });
        } else if (get(notification, 'data.type') === 'brain-boost') {
          window.__router.navigate('brainBoost', {});
        }
      }
    });
  }
};

/**
 * Fire Queued Events from local notifications plugin
 */
const fireLocalNoticationsEvents = () => {
  const localPlugin = getLocalPlugin();
  if (!localPlugin) {
    return;
  }
  localPlugin.fireQueuedEvents();
};

const getDeviceToken = () => registrationId;

export const Notifier = {
  getDeviceToken,
  schedule,
  cancel,
  initLocalClickListener,
  fireLocalNoticationsEvents,
  cancelAll
};
