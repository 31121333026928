import { parseUrl } from '@edapp/utils';
import { Platform } from '@maggie/cordova/platform';

export function openExternalUrl(url: string): void {
  // Only modify urls that are not custom app url scheme
  // ex. areabook://calendar
  if (url.indexOf('://') === -1) {
    url = parseUrl(url);
  }

  if (Platform.isCordova()) {
    window.open(url, '_system');
  } else {
    window.open(url, '_blank', 'noreferrer');
  }
}

export function openExternalUrlIAB(url: string) {
  if (Platform.isCordova()) {
    return window.open(url, '_blank', 'clearcache=yes,clearsessioncache=yes');
  } else {
    return openExternalUrl(url);
  }
}

export const Link = {
  openExternalUrl,
  openExternalUrlIAB
};
