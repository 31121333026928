import type { LxStoreState } from '@maggie/store/types';

const getAllInductionsList = (state: LxStoreState) => {
  return state.inductions.inductionsData.induction_progresses || [];
};

const getInductionsTotalCount = (state: LxStoreState) => {
  return state.inductions.inductionsData.total_size || 0;
};

export const InductionsSelectors = {
  getAllInductionsList,
  getInductionsTotalCount
};
