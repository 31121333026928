import { takeLatest } from 'redux-saga/effects';

import { itly } from '@edapp/analytics-tracking';
import { ENV } from '@maggie/config/env';
import { Platform } from '@maggie/cordova/platform';

import { SessionActionTypes } from './actions';
import type { SessionAction } from './actions';

function* handleRedirectToSSO(action: SessionAction<SessionActionTypes.REDIRECT_TO_SSO>) {
  const { code } = action.payload;
  itly.ssoRedirectSuccess({ company: code });

  if (Platform.isWeb()) {
    window.location.href = ENV.ssoRedirectUrl(code);
  } else {
    throw new Error('SSO redirect is not supported on this platform');
  }
}

export function* watchRedirectToSSO() {
  yield takeLatest(SessionActionTypes.REDIRECT_TO_SSO, handleRedirectToSSO);
}
