import { WidgetUtils } from '@maggie/components/home/widgets/utils';
import type { RouteParams } from '@maggie/core/router/types';
import { CollectionsSelectors } from '@maggie/store/courseware/collections/selectors';
import type { CourseSummaryType } from '@maggie/store/courseware/collections/types';
import { CourseSelectors } from '@maggie/store/courseware/courses/selectors';
import { PlaylistsSelectors } from '@maggie/store/courseware/playlists/selectors';
import type { LxStoreState } from '@maggie/store/types';

import { HomeScreenWidget } from './types';
import { InductionsSelectors } from '../inductions/selectors';

const getForYouWidgets = (state: LxStoreState) => {
  const widgets = [...state.home.forYouWidgets];

  /**
   * If all playlists are completed, we want to move PlaylistsSection before CoursesCompletedSection
   */
  const allPlaylistsCompleted = PlaylistsSelectors.getIncompletePlaylists(state).length === 0;
  if (allPlaylistsCompleted) {
    const playlistsIndex = widgets.indexOf(HomeScreenWidget.PlaylistsSection);
    const completedIndex = widgets.indexOf(HomeScreenWidget.CoursesCompletedSection);
    if (playlistsIndex > -1 && completedIndex > 0) {
      widgets.splice(playlistsIndex, 1);
      widgets.splice(completedIndex - 1, 0, HomeScreenWidget.PlaylistsSection);
    }
  }

  // Move Induction widget to the end if all inductions are completed
  const inductionsList = InductionsSelectors.getAllInductionsList(state);
  const allInductionsCompleted =
    inductionsList.length > 0 &&
    inductionsList.every(induction => induction.completed_steps_count === induction.steps_count);

  if (allInductionsCompleted) {
    const inductionsIndex = widgets.indexOf(HomeScreenWidget.InductionsSection);
    if (inductionsIndex > -1) {
      widgets.splice(inductionsIndex, 1);
      widgets.push(HomeScreenWidget.InductionsSection);
    }
  }
  return widgets;
};

/**
 * Filters out
 *    * courses that have incomplete prerequisites
 *    * courses that have been completed
 *    * courses that are in progress
 */
const getAllIncompleteCourseSummaries = (state: LxStoreState): CourseSummaryType[] => {
  const progress = CourseSelectors.getCoursesProgress(state);
  const courses = CollectionsSelectors.getAllCoursesInCollections(state);

  // filter out completed courses
  return courses.filter(
    c => !progress[c.courseId]?.completed && progress[c.courseId]?.percentageCompleted === 0
  );
};

const getUpNext = (state: LxStoreState) => {
  return state.home.upNext;
};

const getUpNextCount = (state: LxStoreState) => {
  return state.home.upNext.length;
};

const getIsUpNextCompleted = (state: LxStoreState) => {
  const completedCourses = CollectionsSelectors.getCoursesCompleted(state);
  const inProgressCourses = CollectionsSelectors.getCoursesInProgress(state);
  const upNextCourses = getUpNext(state);

  // if home is still loading treat it as not completed.
  if (getIsLoading(state)) {
    return false;
  }
  /**
   * true if,
   * - 0 courses that is in progress
   * - 0 up next courses
   * - > 0 (at least 1) completed courses
   */
  return (
    inProgressCourses.length === 0 && upNextCourses.length === 0 && completedCourses.length > 0
  );
};

const getIsLoading = (state: LxStoreState) => state.home.isLoading;

const getError = (state: LxStoreState) => {
  return (
    state.courseware.collections.fetchCollectionsError ||
    state.courseware.playlists.fetchPlaylistsError ||
    state.courseware.rapidRefreshes.error
  );
};

const getHomeSectionTitle = (state: LxStoreState, routeParams?: RouteParams<'homeSection'>) => {
  if (!routeParams) {
    return '';
  }

  return WidgetUtils.getSectionTitle(routeParams.sectionType as HomeScreenWidget);
};

const getSelectedTabIndex = (state: LxStoreState) => {
  return state.home.tabIndex;
};

const getCoursesAvailability =
  (courses: CourseSummaryType[]) =>
  (s: LxStoreState): Record<string, boolean> => {
    return courses.reduce<Record<string, boolean>>((acc, { courseId, planning }) => {
      if (acc[courseId]) {
        return acc; // already calculated
      }

      acc[courseId] = CourseSelectors.isCourseAvailable(
        courseId,
        planning.startDateTime,
        planning.endDateTime,
        s
      ).isAvailable;
      return acc;
    }, {});
  };

export const HomeSelectors = {
  getAllIncompleteCourseSummaries,
  getUpNext,
  getUpNextCount,
  getIsUpNextCompleted,
  getForYouWidgets,
  getIsLoading,
  getError,
  getHomeSectionTitle,
  getSelectedTabIndex,
  getCoursesAvailability
};
